<template>
  <div>
    <div v-if="isSettingLoading" class="page__loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!isSettingLoading">
      <NavbarComponent />
      <router-view v-slot="{ Component }">
        <Transition name="scale" mode="out-in">
          <component :is="Component" />
        </Transition>
      </router-view>
      <FooterComponent />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarComponent from "@/components/NavbarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "LandingPage",
  data() {
    return {
      isSettingLoading: false,
    };
  },
  components: {
    NavbarComponent,
    FooterComponent,
  },
  computed: {
    ...mapGetters("settingsModule", ["getIsSettingLoading"]),
  },
  mounted() {
    this.isSettingLoading = this.getIsSettingLoading;
  },
  watch: {
    getIsSettingLoading() {
      this.isSettingLoading = this.getIsSettingLoading;
    },
  },
};
</script>

<style lang="scss"></style>
