<template>
  <div>
    <nav class="navbar navbar-expand-xl bg-nav">
      <div class="container">
        <router-link class="navbar-brand p-0" to="/">
          <img
            rel="preload"
            class="mainLogo"
            width="200"
            height="100"
            :src="settings.site_logo"
            alt="site Logo"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="bars-nav fa-solid fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 marg-right">
            <li class="nav-item">
              <a href="">
                <router-link class="nav-link navlink" to="/"
                  >الرئيسية</router-link
                >
              </a>
            </li>
            <li class="nav-item">
              <a href="">
                <router-link class="nav-link navlink" to="/courses"
                  >الدورات</router-link
                >
              </a>
            </li>
            <!-- <li class="nav-item">
                <router-link class="nav-link navlink" to="/tracks"
                  >المسارات</router-link
                >
            </li> -->
            <li class="nav-item" v-if="isAuth">
              <a href="">
                <router-link
                  class="nav-link navlink"
                  :to="{ name: 'ExpertTicketsList' }"
                  >تواصل مع الخبير</router-link
                >
              </a>
            </li>
          </ul>
          <!-- عندم تم تسجيل دخول -->

          <ul
            v-if="isAuth"
            class="navbar-nav ms-auto mb-2 mb-lg-0 marg-right Profile-nav box-Profile-faces-nav"
          >
            <li class="nav-item">
              <div>
                <div class="">
                  <div class="box-All-Notification">
                    <span
                      v-if="this.isNotificationsRead || isNotifacationShow"
                      class="noti-alert"
                    ></span>
                    <div @click="clickNotification()">
                      <div>
                        <div class="nav-Notification bg-icon-search 2">
                          <i class="fa-regular fa-bell"></i>
                        </div>
                      </div>
                    </div>

                    <div class="box-slideToggle-Notification">
                      <div class="box-Notification">
                        <div>
                          <div
                            class="d-flex align-items-center justify-content-between pe-3"
                          >
                            <div
                              class="d-flex align-items-center box-all-regular box-slil-regular"
                            >
                              <div class="pe-3">
                                <i class="fa-regular fa-bell bell"></i>
                              </div>
                              <div>
                                الإشعارات
                                <span>({{ this.isNotificationsRead }})</span>
                              </div>
                            </div>
                          </div>
                          <div class="boder-mess"></div>
                        </div>
                        <div class="box-item-Notification">
                          <div
                            v-for="notifacation in notifacations"
                            :key="notifacation.id"
                          >
                            <router-link
                              :class="{ active: !notifacation.is_read }"
                              :to="notifacation.url"
                            >
                              <div
                                class="d-flex align-items-center box-all-regular"
                                @click="selectNotifacation(notifacation)"
                              >
                                <div class="box-icon-regular">
                                  <i class="fa-regular fa-bell bell"></i>
                                </div>
                                <div class="px-3">
                                  <p>{{ notifacation.title }}</p>
                                  <div class="">
                                    <span>{{ notifacation.created_at }}</span>
                                  </div>
                                </div>
                              </div>
                            </router-link>
                            <div class="boder-mess"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <div>
                <div class="nav-heart">
                  <div class="box-All-Notification">
                    <router-link :to="{ name: 'AllFavorite' }">
                      <div>
                        <div class="nav-Notification bg-icon-search 3">
                          <i class="fa-regular fa-heart"></i>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <div class="box-All-Profile">
                <button
                  aria-label="Profile"
                  @click="clickProfile()"
                  class="btn-nav-Profile"
                >
                  <div class="d-flex justify-content-center align-items-center">
                    <div>
                      <img
                        rel="preload"
                        width="200"
                        height="200"
                        v-if="this.userInfo.avatar != null"
                        :src="this.userInfo.avatar"
                        alt=""
                      />
                      <img
                        rel="preload"
                        width="200"
                        height="200"
                        v-else
                        src="@/assets/images/Avatar.svg"
                        alt=""
                      />
                    </div>
                    <div class="ps-3 pe-1 d-flex">
                      <span class="span-name" v-if="this.isAuth">{{
                        this.userInfo.name
                      }}</span>
                    </div>
                    <div>
                      <span
                        ><i
                          class="icon-communication fa-solid fa-angle-down"
                        ></i
                      ></span>
                    </div>
                  </div>
                </button>
                <div class="box-slideToggle-Profile">
                  <div class="box-Profile">
                    <router-link
                      class="link"
                      active-class="active"
                      :to="{ name: 'profileView' }"
                    >
                      <div class="d-flex align-items-center box-all-content">
                        <div class="px-2 box-Profile-img">
                          <img
                            width="19"
                            height="19"
                            rel="preload"
                            src="../assets/images/Profile.png"
                            alt=""
                          />
                        </div>
                        <div class="name-Profile">الملف الشخصي</div>
                      </div>
                    </router-link>
                    <router-link
                      class="link"
                      active-class="active"
                      :to="{ name: 'EducationalBoard' }"
                    >
                      <div class="d-flex align-items-center box-all-content">
                        <div class="px-2 box-Profile-img">
                          <img
                            width="19"
                            height="19"
                            rel="preload"
                            src="../assets/images/Work.png"
                            alt=""
                          />
                        </div>
                        <div class="name-Profile">لوحتي التعليمية</div>
                      </div>
                    </router-link>
                    <router-link
                      class="link"
                      active-class="active"
                      :to="{ name: 'AllFavorite' }"
                    >
                      <div class="d-flex align-items-center box-all-content">
                        <div class="px-2 box-Profile-img">
                          <img
                            width="19"
                            rel="preload"
                            height="19"
                            src="../assets/images/heart-Profile.png"
                            alt=""
                          />
                        </div>
                        <div class="name-Profile">المفضلة</div>
                      </div>
                    </router-link>
                    <div class="link">
                      <div
                        class="d-flex align-items-center pointer box-all-content"
                        @click="handleLogout"
                      >
                        <div class="px-2 box-Profile-img">
                          <img
                            width="19"
                            height="19"
                            rel="preload"
                            src="../assets/images/Logout.png"
                            alt=""
                          />
                        </div>
                        <div class="name-Profile">تسجيل خروج</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <!-- عندم لم يتم تسجيل دخول -->
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0" v-if="!isAuth">
            <li class="nav-item">
              <button
                type="button"
                class="nav-link Login text-center"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalSignIn"
                aria-label="Login"
              >
                تسجيل دخول
              </button>
            </li>
            <div class="bordr-color"></div>
            <li class="nav-item">
              <button
                type="button"
                class="nav-link registration text-center"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalSignUp"
                aria-label="exampleModalSignUp"
              >
                تسجيل
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- ---------------------------------------------------------------------------------------------------- -->
    <nav class="nav2">
      <div class="container">
        <ul
          class="Profile-nav box-Profile-faces-nav-2 align-items-center justify-content-between"
        >
          <li class="nav-item">
            <div>
              <div class="">
                <div class="box-All-Notification">
                  <div @click="clickNotification()">
                    <div>
                      <div class="nav-Notification bg-icon-search 6">
                        <i class="fa-regular fa-bell"></i>
                      </div>
                    </div>
                  </div>

                  <div class="box-slideToggle-Notification">
                    <div class="box-Notification2">
                      <div class="h-100">
                        <div>
                          <div
                            class="d-flex align-items-center justify-content-between pe-3"
                          >
                            <div
                              class="d-flex align-items-center box-all-regular box-slil-regular"
                            >
                              <div class="pe-3">
                                <i class="fa-regular fa-bell bell"></i>
                              </div>
                              <div>
                                الإشعارات
                                <span>({{ notifacations.length }})</span>
                              </div>
                            </div>
                          </div>
                          <div class="boder-mess"></div>
                        </div>
                        <div class="box-item-Notification">
                          <div
                            v-for="notifacation in notifacations"
                            :key="notifacation.id"
                          >
                            <router-link
                              :class="{ active: !notifacation.is_read }"
                              :to="notifacation.url"
                            >
                              <div
                                class="d-flex align-items-center box-all-regular"
                                @click="selectNotifacation(notifacation)"
                              >
                                <div class="box-icon-regular">
                                  <i class="fa-regular fa-bell bell"></i>
                                </div>
                                <div class="px-3">
                                  <p>{{ notifacation.title }}</p>
                                  <div class="">
                                    <span>{{ notifacation.created_at }}</span>
                                  </div>
                                </div>
                              </div>
                            </router-link>
                            <div class="boder-mess"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li class="nav-item">
            <div>
              <div class="nav-heart">
                <div class="box-All-Notification">
                  <router-link :to="{ name: 'AllFavorite' }">
                    <div>
                      <div class="nav-Notification bg-icon-search 3">
                        <i class="fa-regular fa-heart"></i>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item" v-if="isAuth">
            <div class="box-All-Profile">
              <button
                aria-label="Profile"
                @click="clickProfile()"
                class="btn-nav-Profile"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <div>
                    <img
                      width="200"
                      height="200"
                      rel="preload"
                      v-if="this.userInfo.avatar != null"
                      :src="this.userInfo.avatar"
                      alt=""
                    />
                    <img
                      width="200"
                      height="200"
                      rel="preload"
                      v-else
                      src="@/assets/images/Avatar.svg"
                      alt=""
                    />
                  </div>
                  <div class="ps-3 pe-1 d-flex">
                    <span class="span-name" v-if="this.isAuth">{{
                      this.userInfo.name
                    }}</span>
                  </div>
                  <div>
                    <span
                      ><i class="icon-communication fa-solid fa-angle-down"></i
                    ></span>
                  </div>
                </div>
              </button>
              <div class="box-slideToggle-Profile">
                <div class="box-Profile">
                  <router-link
                    active-class="active"
                    class="link"
                    :to="{ name: 'profileView' }"
                  >
                    <div class="d-flex align-items-center box-all-content">
                      <div class="px-2 box-Profile-img">
                        <img
                          width="19"
                          height="19"
                          rel="preload"
                          src="../assets/images/Profile.png"
                          alt=""
                        />
                      </div>
                      <div class="name-Profile">الملف الشخصي</div>
                    </div>
                  </router-link>
                  <router-link
                    active-class="active"
                    class="link"
                    :to="{ name: 'EducationalBoard' }"
                  >
                    <div class="d-flex align-items-center box-all-content">
                      <div class="px-2 box-Profile-img">
                        <img
                          width="19"
                          height="19"
                          rel="preload"
                          src="../assets/images/Work.png"
                          alt=""
                        />
                      </div>
                      <div class="name-Profile">لوحتي التعليمية</div>
                    </div>
                  </router-link>
                  <router-link
                    active-class="active"
                    class="link"
                    :to="{ name: 'AllFavorite' }"
                  >
                    <div class="d-flex align-items-center box-all-content">
                      <div class="px-2 box-Profile-img">
                        <img
                          width="19"
                          height="19"
                          rel="preload"
                          src="../assets/images/heart-Profile.png"
                          alt=""
                        />
                      </div>
                      <div class="name-Profile">المفضلة</div>
                    </div>
                  </router-link>
                  <div class="link">
                    <div
                      class="d-flex align-items-center box-all-content"
                      @click="handleLogout"
                    >
                      <div class="px-2 box-Profile-img">
                        <img
                          width="19"
                          height="19"
                          rel="preload"
                          src="../assets/images/Logout.png"
                          alt=""
                        />
                      </div>
                      <div class="name-Profile">تسجيل خروج</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import $ from "jquery";

import instance from "@/plugin/axios.js";
import Swal from "sweetalert2";

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "NavbarComponent",
  data() {
    return {
      isAuth: false,
      isNotifacationShow: false,
      userInfo: {},
      settings: {},
      favCourses: [],
      notifacations: [],
    };
  },
  methods: {
    ...mapMutations("authModule", ["isLogin", "setUserInfo"]),
    ...mapActions("authModule", ["profileData"]),
    ...mapActions("notifacationsModule", [
      "notifacationsList",
      "notifacationsIsRead",
    ]),

    clickProfile() {
      $(".box-slideToggle-Profile").fadeToggle(500);
      $(".box-slideToggle-Heart").fadeOut(500);
      $(".box-slideToggle-Notification").fadeOut(500);
      $(".box-slideToggle-search").fadeOut(500);
      $(".fadeOut-nav").fadeOut(500);
    },
    clickHeart() {
      $(".box-slideToggle-Heart").fadeToggle(500);
      $(".box-slideToggle-Profile").fadeOut(500);
      $(".box-slideToggle-Notification").fadeOut(500);
      $(".box-slideToggle-search").fadeOut(500);
      // $(".bg-icon-search").addClass("Actv-nav");
    },
    clickNotification() {
      this.notifacationsList();

      $(".box-slideToggle-Notification").fadeToggle(500);
      $(".box-slideToggle-Profile").fadeOut(500);
      $(".box-slideToggle-Heart").fadeOut(500);
      $(".box-slideToggle-search").fadeOut(500);
    },
    clicksearch() {
      $(".box-slideToggle-search").fadeToggle(500);
      $(".box-slideToggle-Profile").fadeOut(500);
      $(".box-slideToggle-Heart").fadeOut(500);
      $(".box-slideToggle-Notification").fadeOut(500);
    },

    successfulAdded(message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: message,
      });
    },

    // // Error Request
    errorAdded(message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: message,
      });
    },
    handleLogout() {
      instance
        .post("farmer/profile/logout")
        .then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("isAuth");
          this.isLogin(false);
          localStorage.removeItem("userInfo");
          this.successfulAdded("لقد تم تسجيل الخروج بنجاح");
          this.$router.replace({ name: "Home" });
        })
        .catch((error) => this.errorAdded(error));
    },
    selectNotifacation(notifacation) {
      this.notifacationsIsRead(notifacation);
    },
  },
  async created() {
    if (this.getIsAuth) await this.notifacationsList();

    if (Object.keys(this.getUserInfo).length === 0 && this.getIsAuth != false)
      this.profileData();

    // var userInfo = localStorage.getItem("userInfo");
    // this.setUserInfo(JSON.parse(userInfo));
  },
  computed: {
    ...mapGetters("authModule", ["getIsAuth", "getUserInfo"]),
    ...mapGetters("settingsModule", ["getSettings"]),
    ...mapGetters("userCoursesModule", ["getUserCoursesFav"]),
    ...mapGetters("notifacationsModule", [
      "getNotifacations",
      "getIsNotifacations",
    ]),

    //
    isNotificationsRead() {
      let notifacationRead = this.getNotifacations.filter(
        (noti) => !noti.is_read
      );
      return notifacationRead.length;
    },
  },
  mounted() {
    this.isAuth = this.getIsAuth;
    this.userInfo = this.getUserInfo;
    this.settings = this.getSettings;
    this.notifacations = this.getNotifacations;
    this.isNotifacationShow = this.getIsNotifacations;
  },
  watch: {
    getUserCoursesFav() {
      this.favCourses = this.getUserCoursesFav;
    },
    getNotifacations() {
      this.notifacations = this.getNotifacations;
    },
    getIsNotifacations() {
      this.isNotifacationShow = this.getIsNotifacations;
    },
    getIsAuth() {
      this.isAuth = this.getIsAuth;
    },
    getUserInfo() {
      this.userInfo = this.getUserInfo;
    },
    getSettings() {
      this.settings = this.getSettings;
    },
  },
};
</script>

<style lang="scss" scoped>
$main-color: #a7a260;
@import "@/scss/abstracts/mixins";

.mainLogo {
  max-width: 200px;
  height: auto;
}

// ----------------------------------------------------------------------------------------------------
// SignUp
.modal-content {
  border: solid #dddddd 1px !important;
  border-radius: 20px !important;
}

.btn-Close {
  width: 32px;
  height: 32px;
  border: 2px solid #d1d1d1;
  color: #d1d1d1;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-modal h2 {
  font-size: 28px;
  color: #798893;
  font-weight: 100;
}

.box-modal-LogIn-account h3 {
  font-size: 26px;
  color: #434343;
  font-weight: 100;
}

.laboel-LogIn {
  color: #434343;
  font-size: 20px;
}

.box-modal-LogIn-account .input-LogIn,
.box-modal-LogIn-account .select-SignUp {
  border: #efefef solid 1px;
  box-shadow: none;
  width: 100% !important;
  height: 58px;
  border-radius: 6px !important;
  // color: #dedede;
  font-size: 18px;
}

.box-modal-LogIn-account .input-LogIn:focus,
.box-modal-LogIn-account .select-SignUp:focus {
  border: #efefef solid 1px;
  box-shadow: none;
  border-radius: 6px !important;
  // color: #dedede;
  font-size: 18px;
}

.box-modal-LogIn-account .input-LogIn::placeholder,
.box-modal-LogIn-account .select-SignUp::placeholder {
  color: #dedede;
  font-size: 18px;
}

.btn-Forgot-your-password {
  background-color: transparent;
  border: none;
  color: #798893;
  font-size: 16px;
}

.bell {
  font-size: 19px;
  color: #798893;
}

.link {
  &.active .box-all-content {
    border-right: solid 3px $main-color;
    background-color: rgb(167, 162, 96, 10%);
  }
  .box-all-content {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    padding: 0.3rem;
    transition: all 0.8s;
    border-right: solid 3px transparent;
    &:hover {
      border-right: solid 3px $main-color;
      background-color: rgb(167, 162, 96, 10%);
    }
  }
}

.label-remember-me {
  color: #798893;
  font-size: 16px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.btn-sign-in {
  background-color: $main-color;
  height: 60px;
  width: 100%;
  border: none;
  color: #ffffff;
  font-size: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Register-a-new-account {
  background-color: transparent;
  border: none;
  color: $main-color;
  font-size: 22px;
  text-decoration: underline;
}

.box-All-Modal-SignUp {
  max-width: 970px !important;
}

// ----------------------------------------------------------------------------------------------------

.bg-nav {
  background-color: #ffffff;
  padding: 0 !important;
  position: relative;
  z-index: 10;
}

a .nav-link {
  padding: 1.5rem 1rem 1.5rem 0rem;
  margin: 0 0.5rem;
  color: #798893;
  font-size: 22px;
  position: relative;
  transition: all 0.5s;
  font-weight: 300;
}
.navlink {
  cursor: pointer;
}
a .navlink:hover {
  color: $main-color;
}

.navlink:hover::after {
  width: 75%;
}
.navlink:hover::before {
  /* width: 75%; */
  left: 0;
  opacity: 1;
}
.navlink::after {
  content: "";
  position: absolute;
  background-color: $main-color;
  height: 3px;
  width: 0%;
  /* width: 70px; */

  right: 0.2rem;
  bottom: -1px;

  border-radius: 100px;
  transition: all 0.5s;
}

.nav-item a .router-link-exact-active {
  position: relative;
  text-decoration: none;
  color: $main-color !important;
  font-size: 22px;
  letter-spacing: 0.5px;
}

.navlink::before {
  content: "";
  position: absolute;
  background-color: $main-color;
  height: 4px;
  width: 4px;
  left: 100%;
  bottom: -1px;
  border-radius: 50%;
  transition: all 0.5s 0.3s;
  opacity: 0;
}

.nav-item a .router-link-exact-active::after {
  content: "";
  position: absolute;
  background-color: $main-color;
  height: 3px;
  width: 75%;
  /* width: 70px; */
  right: 0.2rem;
  bottom: -1px;
  border-radius: 100px;
}

.nav-item a .router-link-exact-active::before {
  content: "";
  position: absolute;
  background-color: $main-color;
  height: 4px;
  width: 4px;
  left: 0;
  bottom: -1px;
  opacity: 1;
  border-radius: 50%;
}

.marg-right {
  margin-right: 2rem;
}

.bordr-color {
  border-left: $main-color solid 2px;
  /* margin: 1.2rem 0; */
  /* height: 50%; */
  /* width: 2px; */
  /* background-color: $main-color; */
}

.navbar-brand .imgLogo {
  width: 206px;
  /* height: 100px; */
}

.nav-item .registration,
.nav-item .Login {
  border: 2px solid $main-color;
  border-radius: 30px;
  font-size: 20px;
  width: 150px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s;
  text-align: center;
}

.nav-item .Login {
  /* padding: 0.4rem 0.8rem !important; */
  color: $main-color !important;
  background-color: transparent;
  margin-left: 0.5rem;
}

.nav-item .Login:hover {
  color: #ffffff !important;
  background-color: $main-color;
}

.nav-item .registration {
  /* padding: 0.4rem 2rem !important; */
  background-color: $main-color !important;
  color: #ffffff !important;
  margin-right: 0.5rem;
}

.nav-item .registration:hover {
  color: $main-color !important;
  background-color: transparent !important;
  border: 2px solid $main-color !important;
}

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
  /* background-color: coral; */
}

.navbar-toggler .bars-nav {
  color: $main-color !important;
  font-size: 2.4rem !important;
}

.icon-communication {
  margin-right: 0.5rem;
}

.btn-nav-Profile {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #798893;
  font-size: 22px;
}

.btn-nav-Profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.name-Profile {
  font-size: 13px;
  color: #434343;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.name-ConnectUs {
  font-size: 13px;
  color: #434343;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.nav-Notification {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #798893;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #798893;
  font-size: 1.4rem;
  // background-color: brown;
}

// ---------------------------------------------------------------------------------------------------------
.box-All-Profile,
.box-All-Heart,
.box-All-Notification {
  position: relative;
  z-index: 10;
  .noti-alert {
    background-color: var(--main-color);
    position: absolute;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 0px;
    right: 0px;
    border: 1px solid #fff;
  }
}

.box-slideToggle-Profile {
  display: none;
}

.box-slideToggle-search,
.box-slideToggle-Heart,
.box-slideToggle-Notification {
  display: none;
  position: relative;
  // z-index: 500;
}
.box-Profile {
  overflow: visible !important;
  &::after {
    content: "";
    position: absolute;
    top: -34px;
    left: 48px;
    width: 100%;
    height: 35px;
    background-image: url("../assets/images/triangle.png");
    background-repeat: no-repeat;
    z-index: 99;
  }
}
.box-slideToggle-search::after,
.box-slideToggle-Notification::after,
.box-slideToggle-Heart::after {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 100%;
  height: 35px;
  background-image: url("../assets/images/triangle.png");
  background-repeat: no-repeat;
  z-index: 99;
}

.box-all-ConnectUs {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  padding: 0.3rem;
  transition: all 0.8s;
  border-right: solid 3px transparent;
}

.icon-caret {
  position: absolute;
  top: 43px;
  left: 7.5%;
  transform: translateX(-50%);
  color: brown;
  font-size: 26px;
  z-index: 1;
  // background-color: aquamarine;
}

.icon-caret img {
  width: 50px;
  height: 40px;
}

.icon-caret-heart {
  position: absolute;
  top: 43px;
  left: 0px;
  font-size: 26px;
  z-index: 1;
}

.icon-caret-heart img {
  width: 50px;
  height: 40px;
}

.box-Profile {
  position: absolute;
  right: 57%;
  /* transform: -50%; */
  /* right: 0; */
  top: 4.5rem;
  background-color: #ffffff;
  width: 155px;
  z-index: 20;
  box-shadow: 0 0 10px #ccc;
  overflow: hidden;
  // height: 231px;
  // border-radius: 10px;
  padding-top: 1rem;
}

.box-heart {
  position: absolute;
  left: -191px;
  top: 4.5rem;
  background-color: #ffffff;
  width: 400px;
  z-index: 10;
  box-shadow: 0 0 10px #ccc;
  overflow: hidden;
  border-radius: 10px;
  padding-top: 1rem;
}

.box-Notification {
  position: absolute;
  left: -172px;
  top: 2.2rem;
  background-color: #ffffff;
  width: 405px;
  z-index: 10;
  box-shadow: 0 0 10px #ccc;
  overflow: hidden;
  // border-radius: 10px;
  padding-top: 0.6rem;
}
.box-Notification2 {
  position: absolute;
  left: -172px;
  top: 2.2rem;
  background-color: #ffffff;
  width: 405px;
  z-index: 10;
  box-shadow: 0 0 10px #ccc;
  overflow: hidden;
  // border-radius: 10px;
  padding-top: 0.6rem;
}

.box-input-search {
  position: absolute;
  left: -172px;
  top: 2.2rem;
  background-color: #ffffff;
  width: 405px;
  z-index: 10;
  box-shadow: 0 0 10px #ccc;
  overflow: hidden;
  height: 500px;
  // border-radius: 10px;
  padding-top: 0.6rem;
}
.box-Profile p {
  color: #3d3d3d;
  // padding-right: 1rem;
  font-size: 16px;
}

.box-Profile span {
  color: rgb(61, 61, 61, 40%);
  font-size: 16px;
}

.boder-mess {
  border-bottom: #e9e9e9 solid 1px;
}

.box-icon-regular {
  width: 60px;
  height: 60px;
  background-color: #e9e7e7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-all-regular {
  padding: 0.75rem 0.8rem;
}

.box-all-regular p {
  font-size: 15px;
  color: #434343;
}

.box-all-regular div span {
  font-size: 16px;
  color: #d1d1d1;
}

.box-slil-regular,
.box-slil-regular div,
.box-slil-regular div span {
  color: #242424;
  font-size: 20px;
}

.Text-View-all-notifications {
  color: #d1d1d1;
  font-size: 16px;
}

.box-item-Notification {
  overflow-y: auto;
  max-height: 60vh;
  a.active {
    background-color: #eee;
    display: block;
  }
}

.nav-heart {
  margin-right: 1rem;
  margin-left: 1rem;
}
.box-nav-Notification {
  margin-left: 1rem;
}
.bg-icon-search {
  background-color: transparent;
  border: #798893 solid 1px;
  color: #798893;
  transition: all 0.5s;
}
.bg-icon-search:hover,
.Actv-nav {
  background-color: $main-color;
  color: #fff;
  border: $main-color solid 1px;
}

.box-input-search-education {
  position: relative;
}
.btn-search-ind-input {
  position: absolute;
  right: 1rem;
  top: 50%;
  color: #bfc4ce;
  font-size: 1.2rem;
  transform: translateY(-50%);
}
.btn-search-ind-x {
  position: absolute;
  left: 1rem;
  top: 50%;
  color: #bfc4ce;
  font-size: 1.2rem;
  transform: translateY(-50%);
}
.btn-search-ind-input:hover,
.btn-search-ind-x:hover {
  color: #bfc4ce;
}
.input-search-education {
  border-bottom: #e9e9e9 solid 1px;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
  width: 100% !important;
  height: 58px;
  border-radius: 0px !important;
  // color: #E9E9E9;
  font-size: 14px;
  padding-right: 4rem;
  padding-left: 4rem;
}

.input-search-education:focus {
  // color: #E9E9E9;
  font-size: 14px;
  border-bottom: #e9e9e9 solid 1px;
  border-top: none;
  border-right: none;
  border-left: none;
  box-shadow: none;
}

.input-search-education::placeholder {
  color: #bfc4ce;
  font-size: 14px;
}
.box-icon-clock {
  color: #e9e9e9;
  font-size: 14px;
}
.box-all-regular .p-search-clock {
  padding: 0;
  margin: 0;
  color: #e9e9e9;
  font-size: 14px;
}
//-----------------------------------------------------------------------------------------------------------------------
@media screen and (max-width: 992px) {
  .nav-item {
    margin: 1rem 0;
  }

  .nav-mdi {
    transition: all 0.5s;
  }

  .nav-mdi.animate {
    top: 5px;
  }

  .nav-mdi.sticky {
    top: 4.5rem;
  }

  .marg-right {
    margin-right: 0;
  }

  .nav-link {
    padding: 0.8rem 1.5rem;
    margin: 0 0.5rem;

    font-size: 22px;
  }

  .mun-align {
    display: block;
    margin-left: 30px;
  }
}
.box-Profile-faces-nav-2 {
  display: none;
}
@media screen and (max-width: 1199.2px) {
  .bg-nav {
    /* background-color: crimson; */
    padding: 1rem 0 !important;
    // background-color: brown;
  }
  .navbar-collapse {
    padding: 0 50px;
    position: absolute;
    background-color: #ffffff;
    height: 100vh;
    top: 10.1rem;
    left: 0;
    /* overflow: auto; */
    padding-bottom: 2rem;
    // z-index: 1111111111111;
  }

  .box-Profile-faces-nav {
    // background-color: brown;
    display: none;
  }
  .box-Profile-faces-nav-2 {
    display: flex;
    // background-color: brown;
    // border-top: solid 1px #dddddd;
    padding: 0.2rem 0;
  }
  .box-Notification {
    right: 0;
    width: 310px;
    height: 422px;
  }
  .box-Notification2 {
    left: -275%;
    width: 310px;
    height: 422px;
  }
  .box-Notification3 {
    left: -11px;
    width: 310px;
    height: 422px;
  }
  .box-slil-regular div {
    font-size: 14px !important;
  }
  .Text-View-all-notifications {
    font-size: 12px !important;
  }
  .box-all-regular p,
  .box-all-regular div span {
    font-size: 12px !important;
  }
  .box-icon-regular {
    width: 50px;
    height: 45px;
  }
  .box-all-regular {
    padding: 0.5rem;
  }
  .box-Profile {
    right: 0;
  }
  .icon-caret {
    right: 0;
  }
  .box-input-search {
    width: 300px;
    left: -256px;
  }
  .nav2 {
    // background-color: aqua;
    border-top: solid 1px #e9e9e9;
    border-bottom: solid 1px #e9e9e9;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .box-search-mobil {
    display: none;
  }
}

@media screen and (max-width: 1370px) {
  .imgwavyLineTop-imgwavyLineBottom {
    right: 16rem;
  }

  .nav-item .registration,
  .nav-item .Login {
    border: 2px solid $main-color;
    border-radius: 30px;
    // font-size: px;
    // width: 140px;
    // height: 48px;
    padding: 0;
  }

  .nav-item a .router-link-exact-active::after {
    bottom: -7px;
  }

  .nav-item a .router-link-exact-active::before {
    bottom: -7px;
  }
}
.span-name {
  @include nowarp;
  max-width: 140px;
  display: inline-block;
}
</style>
