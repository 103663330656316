import instance from "@/plugin/axios";
import Swal from "sweetalert2";
import router from "@/router";

// Success Added
let successfulAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "success",
    title: message,
  });
};

// // Error Request
let errorAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "error",
    title: message,
  });
};
const expertModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    inquries: [],
    inquiry: {},
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getInquries: (state) => state.inquries,
    getInquiry: (state) => state.inquiry,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    //
    setAllInquries: (state, payload) => {
      state.inquries = payload;
    },
    setAddInquries: (state, payload) => {
      state.inquries = payload;
    },
    setInquiryFromState: (state, payload) => {
      state.inquiry = state.inquries.find((inquiry) => inquiry.id == payload);
    },
    setInquiryByID: (state, payload) => {
      state.inquiry = payload;
    },
    setAddMessage: (state, payload) => {
      state.inquiry.messages.push(payload);
    },
  },
  actions: {
    allInquiry({ commit }) {
      instance
        .get(`farmer/expert-inquries`)
        .then((data) => {
          commit("setAllInquries", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    inquiryById({ commit }, payload) {
      instance
        .get(`farmer/expert-inquries/${payload}`)
        .then((data) => {
          commit("setInquiryByID", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    sendMessage({ commit }, payload) {
      instance
        .post(
          `farmer/expert-inquries/${payload.id}/sent-message`,
          payload.message
        )
        .then((data) => {
          commit("setAddMessage", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    openingNewInguries({ commit }, payload) {
      instance
        .post(`farmer/expert-inquries/new`, payload)
        .then((data) => {
          commit("setInquiryByID", data.data.data);
          successfulAdded(data.data.message);
          router.push({
            name: "ExpertTicketsList",
          });
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    sendInquiry({ commit }, payload) {
      instance
        .post(`connectus`, payload)
        .then(() => {
          commit("setLoading", false);
          successfulAdded("تم إرسال التذكرة بنجاح");
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
  },
};

export default expertModule;
