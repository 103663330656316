<template>
  <footer>
    <div class="layer-footer">
      <div class="footer">
        <div class="">
          <div class="container">
            <div class="py-5">
              <div class="row">
                <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 my-3">
                  <div class="box-footer-log-text">
                    <router-link to="/">
                      <img
                        width="200"
                        height="100"
                        rel="preload"
                        class="imgLogo"
                        :src="footer_logo"
                        alt=""
                      />
                    </router-link>

                    <div class="pt-3">
                      <div
                        class="footer-text"
                        v-for="(text, index) in footer"
                        :key="index"
                        v-html="text.text"
                      ></div>
                      <div class="box-icon">
                        <a
                          v-for="(social, index) in this.social_media"
                          :key="index"
                          :href="social.url"
                          class="iconImg pointer"
                          target="_blank"
                        >
                          <i
                            v-if="social.key == 'facebook'"
                            class="icon-so fa-brands fa-facebook-f"
                          ></i>
                          <i
                            v-if="social.key == 'youtube'"
                            class="icon-so fa-brands fa-youtube"
                          ></i>
                          <i
                            v-if="social.key == 'twitter'"
                            class="icon-so fa-brands fa-twitter"
                          ></i>
                          <i
                            v-if="social.key == 'linkedin'"
                            class="icon-so fa-brands fa-linkedin-in"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-3 col-lg-4 col-md-12 col-sm-12 box-justify-content-center my-3"
                >
                  <div class="box-footer-link">
                    <h2 v-for="(text, index) in footer" :key="index">
                      <a
                        v-if="!text.hasOwnProperty('footer_link')"
                        :href="text.footer_link"
                        >موقع المركز الوطني للنخيل والتمور</a
                      >
                      <a target="_blank" href="https://ncpd.gov.sa/"
                        >موقع المركز الوطني للنخيل والتمور</a
                      >
                    </h2>

                    <h2>
                      <router-link to="/#homeSteps">مسيرة التطور</router-link>
                    </h2>

                    <h2>
                      <router-link
                        class="navbar-brand p-0"
                        to="/HowToUseThePlatformView"
                        >كيفية استخدام المنصة
                      </router-link>
                    </h2>
                    <h2>
                      <router-link to="/ProgramPlanView">
                        خطة البرنامج
                      </router-link>
                    </h2>
                  </div>
                </div>

                <div
                  class="col-xl-3 col-lg-4 col-md-12 col-sm-12 box-justify-content-center my-3"
                >
                  <div class="box-footer-link">
                    <h2>
                      <router-link to="/AsynchronousPresencePolicyView">
                        سياسية الحضور غير المتزامن
                      </router-link>
                    </h2>
                    <h2>
                      <router-link to="/HowToGuidesView">
                        الأدلة الإرشادية
                      </router-link>
                    </h2>
                    <h2>
                      <router-link to="/TermsOfUseView">
                        شروط الاستخدام
                      </router-link>
                    </h2>
                    <h2>
                      <router-link to="/PrivacyPolicyView">
                        سياسة الخصوصية
                      </router-link>
                    </h2>
                  </div>
                </div>
                <div
                  class="col-xl-3 col-lg-4 col-md-12 col-sm-12 box-justify-content-center my-3"
                >
                  <div class="box-footer-link">
                    <!-- <h2>
                        <router-link to="/ComplaintsSuggestions">
                          الشكاوى والاقتراحات
                        </router-link>
                      </h2> -->

                    <h2>
                      <router-link to="/connectus"> تواصل معنا </router-link>
                    </h2>
                    <h2>
                      <router-link to="/AboutUsView"> عن المنصة </router-link>
                    </h2>
                    <h2>
                      <router-link to="/CommonQuestionsView">
                        الأسئلة الشائعة
                      </router-link>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center Rights-reserved">
            <div
              class="container"
              v-for="(text, index) in footer"
              :key="index"
              v-html="text.copyrights"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterComponent",
  data() {
    return {
      isLoading: false,
      footer_logo: "",
      footer: {},
      social_media: [],
    };
  },
  methods: {},
  computed: {
    ...mapGetters("settingsModule", ["getSettings", "getIsSettingLoading"]),
  },
  mounted() {
    this.isLoading = this.getIsSettingLoading;
    this.footer_logo = this.getSettings.footer_logo;
    this.footer = this.getSettings.footer;
    this.social_media = this.getSettings.social_media;
  },
  watch: {
    getSettings() {
      this.footer = this.getSettings.footer;
      this.footer_logo = this.getSettings.footer_logo;
      this.social_media = this.getSettings.social_media;
    },
    getIsSettingLoading() {
      this.isLoading = this.getIsSettingLoading;
    },
  },
};
</script>

<style lang="scss" scoped>
$main-color: #a7a260;

.imgLogo {
  max-width: 250px;
  height: auto;
  margin-bottom: 10px;
}

.footer {
  background-image: url("../assets/images/bgimg.png");
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center center;
}

.box-icon {
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  margin-top: 1rem;
  .iconImg:not(:last-of-type) {
    position: relative;
    margin-left: 1.3rem;
    &::before {
      content: "";
      position: absolute;
      width: 1px;
      height: 80%;
      background-color: #ddd;
      left: calc(1.3rem / -2);
    }
  }
}

.immmmm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.layer-footer {
  background-color: #807c42;

  height: 100%;
}

.box-footer-log-text h2 {
  font-size: 20px;
  color: #ffffff;
  font-weight: bolder;
}

.box-footer-log-text p {
  font-size: 18px;
  color: #ffffff;
  font-weight: lighter;
  // line-height: 40px;
  // width: 80%;
}

.box-footer-link h2 {
  padding-bottom: 2rem;
  font-weight: lighter;
}

.box-footer-link h2 a {
  font-size: 20px;
  font-weight: lighter;
  color: #ffffff;
}

.box-icon .iconImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255, 0.2);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.iconImg .icon-so {
  font-size: 1.4rem;
}
.box-icon .iconImg:hover {
  color: $main-color;
  background-color: #fff;
}

.bodr-lift-icon {
  background-color: rgb(255, 255, 255, 0.2);
  width: 1px;
  height: 1.5rem;
  margin: 1rem 0.5rem;
}

.Rights-reserved {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.8rem;
  font-size: 24px;
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .box-justify-content-center {
    display: flex;
    justify-content: flex-end;
  }
}
.row-footer {
  padding: 0;
}
.footer-text {
  color: #ffffff;
  font-weight: lighter;
  font-size: 18px;
}
</style>
