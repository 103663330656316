import instance from "@/plugin/axios";

const coursesModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    courses: [],
    topCourses: [],
    course: {},
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getCousres: (state) => state.courses,
    getTopCousres: (state) => state.topCourses,
    getCourse: (state) => state.course,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    //
    setAddCourses: (state, payload) => {
      state.courses = payload;
    },
    setTopCourses: (state, payload) => {
      state.topCourses = payload;
    },
    setCourseFromState: (state, payload) => {
      state.course = state.courses.find((course) => course.id == payload);
    },
    setCourseByID: (state, payload) => {
      state.course = payload;
    },
  },
  actions: {
    async allCourse({ commit }) {
      commit("setLoading", true);
      commit("setFirstLoad", true);
      instance
        .get("courses")
        .then((data) => {
          commit("setAddCourses", data.data.data);
          commit("setLoading", false);
          commit("setFirstLoad", false);
        })
        .catch((error) => console.log(error));
    },
    async topCourses({ commit }) {
      commit("setLoading", true);
      commit("setFirstLoad", true);
      instance
        .get("courses/top-8")
        .then((data) => {
          commit("setTopCourses", data.data.data);
          commit("setLoading", false);
          commit("setFirstLoad", false);
        })
        .catch((error) => console.log(error));
    },
    courseFromState({ commit }, payload) {
      commit("setLoading", true);
      commit("setCourseFromState", payload);
      commit("setLoading", false);
    },
    async coursesSearch({ commit }, payload) {
      commit("setLoading", true);
      instance
        .get(`courses/search?keyword=${payload}`)
        .then((data) => {
          commit("setAddCourses", data.data.data);
          commit("setLoading", false);
        })
        .catch((error) => console.log(error));
    },
    async courseById({ commit }, payload) {
      commit("setLoading", true);
      instance
        .get(`courses/${payload}`)
        .then((data) => {
          commit("setCourseByID", data.data.data);
          commit("setLoading", false);
        })
        .catch((error) => console.log(error));
    },
  },
};

export default coursesModule;
