import { createRouter, createWebHistory } from "vue-router";

//========================== Landing Page =============================//
import LandingPageView from "@/views/LandingView";

//========================== Landing Page =============================//
import UserPanelView from "@/views/UserView";
import store from "@/store";

const routes = [
  //================= User Panel ================//
  {
    path: "/user",
    name: "user",
    component: UserPanelView,
    beforeEnter: (to, from, next) => {
      if (store.state.authModule.isAuth == false) {
        next("/");
      } else {
        next();
      }
    },
    children: [
      {
        path: "Profile",
        name: "profileView",
        component: () => import("@/views/userPages/ProfilePersonlyView"),
      },

      {
        path: "Favorites",
        name: "AllFavorite",
        component: () => import("@/views/userPages/FavoriteView"),
      },
      // {
      //   path: "Notifications",
      //   name: "AllNotifications",
      //   component: () => import("@/views/userPages/ViewAllNotificationsView"),
      // },
      {
        path: "Educational_Board",
        name: "EducationalBoard",
        component: () => import("@/views/userPages/MyEducationalBoardView"),
      },
      {
        path: "/QuestionnaireOptionalView/:id",
        name: "QuestionnaireOptionalView",
        component: () => import("@/views/userPages/QuestionnaireOptionalView"),
      },
      {
        path: "/HomeCourseView/:id",
        name: "HomeCourseView",
        component: () => import("@/views/userPages/HomeCourseView"),
      },

      {
        path: "/FinalTestMandatoryView/:id",
        name: "FinalTestMandatoryView",
        component: () => import("@/views/userPages/FinalTestMandatoryView"),
      },
      {
        path: "/TestAnswers/:id",
        name: "testAnswers",
        component: () => import("@/views/userPages/TestQuestionAnswer"),
      },
      // {
      //   path: "/CreateGardenRegisterNowView",
      //   name: "CreateGardenRegisterNowView",
      //   component: () =>
      //     import("@/views/userPages/CreateGardenRegisterNowView"),
      // },
    ],
  },
  //================= Landing Page ================//
  {
    path: "/",
    name: "landing",
    component: LandingPageView,
    children: [
      // Navbar Links
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/landingPages/HomeView"),
      },
      {
        path: "/courses",
        name: "course",
        component: () => import("@/views/landingPages/CoursesView"),
      },
      // {
      //   path: "/tracks",
      //   name: "tracks",
      //   component: () => import("@/views/landingPages/TracksView"),
      // },
      {
        path: "/connectus",
        name: "connectus",
        component: () => import("@/views/landingPages/ConnectUsView"),
      },
      // Chating With Expert
      {
        path: "/Open_Ticket_With_Expert",
        name: "OpenTicketWithExpert",
        component: () => import("@/views/landingPages/OpenTicketWithExpert"),
      },
      {
        path: "/Expert_Tickets_List",
        name: "ExpertTicketsList",
        component: () => import("@/views/landingPages/ExpertTicketsList"),
      },
      {
        path: "/ContactWithExpert/:id",
        name: "ContactWithExpert",
        component: () => import("@/views/landingPages/ContactWithExpert"),
      },
      // Footer Links
      {
        path: "/HowToUseThePlatformView",
        name: "HowToUse",
        component: () => import("@/views/landingPages/HowToUseThePlatformView"),
      },
      {
        path: "/ProgramPlanView",
        name: "ProgramPlan",
        component: () => import("@/views/landingPages/ProgramPlanView"),
      },
      {
        path: "/PrivacyPolicyView",
        name: "PrivacyPolicy",
        component: () => import("@/views/landingPages/PrivacyPolicyView"),
      },
      {
        path: "/TermsOfUseView",
        name: "TermsOfUse",
        component: () => import("@/views/landingPages/TermsOfUseView"),
      },
      {
        path: "/HowToGuidesView",
        name: "HowToGuides",
        component: () => import("@/views/landingPages/HowToGuidesView"),
      },
      {
        path: "/AsynchronousPresencePolicyView",
        name: "AsyncPolicy",
        component: () =>
          import("@/views/landingPages/AsynchronousPresencePolicyView"),
      },
      {
        path: "/AboutUsView",
        name: "AboutUs",
        component: () => import("@/views/landingPages/AboutUsView"),
      },
      // {
      //   path: "/ComplaintsSuggestions",
      //   name: "ComplaintsSuggestions",
      //   component: () => import("@/views/landingPages/ComplaintsSuggestions"),
      // },
      //
      {
        path: "/CommonQuestionsView",
        name: "CommonQuestionsView",
        component: () => import("@/views/landingPages/CommonQuestionsView"),
      },
      {
        path: "/CreateOrchardView/:id",
        name: "CreateOrchardView",
        component: () => import("@/views/landingPages/CreateOrchardView"),
      },
    ],
  },
];
// const router = createRouter({
//   history: createWebHistory(),
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition;
//     } else {
//       return { x: 0, y: 0 };
//     }
//   },
// });
const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
  strict: true, // applies to all routes

  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
