import instance from "@/plugin/axios";

const certificationsModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    certification: {},
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getCertification: (state) => state.certification,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    setCertification: (state, payload) => {
      state.certification = payload;
    },
  },
  actions: {
    async allCertifications({ commit }) {
      instance
        .get(`farmer/certifications`)
        .then(() => {
          commit("", "");
        })
        .catch((error) => console.log(error));
    },
    async certificationByID({ commit }, payload) {
      instance
        .get(`farmer/certifications/${payload}`)
        .then(() => {
          commit("", "");
        })
        .catch((error) => console.log(error));
    },
    async generateCertificationByID({ commit }, payload) {
      instance
        .post(`farmer/certifications/${payload}/generate`)
        .then((data) => {
          commit("setCertification", data.data.data);
        })
        .catch((error) => console.log(error));
    },
  },
};

export default certificationsModule;
