import axios from "axios";

const instance = axios.create({
  isFile: false,
  // baseURL: "https://ma-auth.ncpd.gov.sa/api/",
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
//3
instance.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    config.headers["api-key"] = "Bearer " + localStorage.getItem("token");
  }
  if (config.isFile) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

export default instance;
