import instance from "@/plugin/axios";
import Swal from "sweetalert2";

// Success Added
let successfulAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "success",
    title: message,
  });
};

// // Error Request
let errorAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "error",
    title: message,
  });
};

const discussionsModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    discussions: [],
    favDiscussions: [],
    discussion: {},
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getDiscussions: (state) => state.discussions,
    getFavDiscussions: (state) => state.favDiscussions,
    getDiscussion: (state) => state.discussion,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    // Discussions
    setAllDiscussions: (state, payload) => {
      state.discussions = payload;
    },
    setFavDiscussions: (state, payload) => {
      state.favDiscussions = payload;
    },
    setDiscussion: (state, payload) => {
      state.discussion = payload;
    },
    setUpdateDiscussion: (state, payload) => {
      let findIndex = state.discussions.findIndex(
        (discussion) => discussion.id === payload.id
      );
      state.discussions[findIndex] = payload;
    },

    // ======
    setNewDiscussion: (state, payload) => {
      state.discussions.push(payload);
    },

    setRemoveFavDiscussion: (state, payload) => {
      let findIndex = state.discussions.findIndex(
        (discussion) => discussion.id === payload.id
      );
      state.discussions[findIndex].is_favorit =
        !state.discussions[findIndex].is_favorit;
    },
    setDiscussionSubcribe: (state, payload) => {
      let findIndex = state.discussions.findIndex(
        (discussion) => discussion.id === payload.id
      );
      state.discussions[findIndex].is_subscribed = payload.is_subscribed;
    },

    setRemoveDiscussion: (state, payload) => {
      let findIndex = state.discussions.findIndex(
        (discussion) => discussion.id === payload.discussion_id
      );
      state.discussions.splice(findIndex, 1);
    },
    setRemoveDiscussionComment: (state, payload) => {
      let findIndex = state.discussion.comments.findIndex(
        (comment) => comment.id === payload.comment_id
      );
      state.discussion.comments.splice(findIndex, 1);
    },
    setRemoveDiscussionImage: (state) => {
      state.discussion.image = null;
    },
    setUpdateDiscussionComment: (state, payload) => {
      let findIndex = state.discussion.comments.findIndex(
        (comment) => comment.id === payload.comment_id
      );
      state.discussion.comments[findIndex].body = payload.data.body;
    },
    setDecreamentDiscussion: (state, payload) => {
      state.discussion.comments_count -= payload;
    },
  },
  actions: {
    async allDiscussions({ commit }, payload) {
      instance
        .get(`farmer/discussions/${payload}`)
        .then((data) => {
          commit("setAllDiscussions", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    async allFavDiscussions({ commit }, payload) {
      instance
        .get(`farmer/discussions/favorit/${payload}`)
        .then((data) => {
          commit("setFavDiscussions", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    async addFavDiscussions({ commit }, payload) {
      instance
        .post(`farmer/discussions/${payload.id}/add-remove-favorits`)
        .then((data) => {
          commit("setRemoveFavDiscussion", payload);
          successfulAdded(data.data.message);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async discussionByID({ commit }, payload) {
      instance
        .get(`farmer/discussions/get/${payload}`)
        .then((data) => {
          commit("setDiscussion", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    async createDiscussion({ commit }, payload) {
      instance
        .post(`farmer/discussions/create`, payload, { isFile: true })
        .then((data) => {
          commit("setNewDiscussion", data.data.data);
          successfulAdded(data.data.message);
          commit("setLoading", false);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async updateDiscussion({ commit }, payload) {
      instance
        .post(`farmer/discussions/${payload.id}/update`, payload.data, {
          isFile: payload.isFile,
        })
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setUpdateDiscussion", data.data.data);
          commit("setLoading", false);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    async deleteDiscussion({ commit }, payload) {
      instance
        .delete(`farmer/discussions/${payload.discussion_id}`)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setRemoveDiscussion", payload);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async deleteDiscussionImage({ commit }, payload) {
      instance
        .post(`farmer/discussions/${payload.id}/delete-image`)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setRemoveDiscussionImage");
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async reportDiscussion({ commit }, payload) {
      instance
        .post(`farmer/discussions/${payload.discussion_id}/report`, payload)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setLoading", false);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    // ===============================
    async createDiscussionComment({ commit }, payload) {
      instance
        .post(
          `farmer/discussions/${payload.discussion_id}/comments/create`,
          payload.data
        )
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setLoading", false);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async deleteDiscussionComment({ commit }, payload) {
      instance
        .delete(
          `farmer/discussions/${payload.discussion_id}/comments/delete/${payload.comment_id}`
        )
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setRemoveDiscussionComment", payload);
          commit("setDecreamentDiscussion", 1);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async updateDiscussionComment({ commit }, payload) {
      instance
        .post(
          `farmer/discussions/${payload.discussion_id}/comments/update/${payload.comment_id}`,
          payload.data
        )
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setUpdateDiscussionComment", payload);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async reportCommentDiscussion({ commit }, payload) {
      instance
        .post(
          `farmer/discussions/${payload.discussion_id}/comments/report/${payload.comment_id}`,
          payload
        )
        .then((data) => {
          successfulAdded(data.data.message);
          commit("", "");
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    async makeDiscussionSubscribed({ commit }, payload) {
      instance
        .post(`farmer/discussions/${payload.id}/email-subscribtion`)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setDiscussionSubcribe", payload);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    async makeDiscussionUnsubscribed({ commit }, payload) {
      instance
        .post(`farmer/discussions/${payload.id}/email-unsubscribtion`)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setDiscussionSubcribe", payload);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
  },
};

export default discussionsModule;
