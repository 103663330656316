import instance from "@/plugin/axios";

import { getMessaging, onMessage } from "firebase/messaging";

const notifacationsModule = {
  namespaced: true,
  state: {
    isLoading: true,
    notifacations: [],
    isNotification: false,
    server_key:
      "AAAAZGjDXY8:APA91bGm3KgrpPx5Z1FhOX5YW3oI3sBG0R1dpuxPWKu7uq4CX_HrO05Qs6wcHEyytmCOS3joke980JH2UMYLO4DrihSVH6ylmFw8t7g9vysW9OsLdTP_NEmUDwPOKrJNiGVmybV9Xyqf",
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getNotifacations: (state) => state.notifacations,
    getIsNotifacations: (state) => state.isNotification,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    setNotifacations: (state, payload) => {
      state.notifacations = payload;
    },
    setIsNotifacations: (state, payload) => {
      state.isNotification = payload;
    },
    setNotifacationRead: (state, payload) => {
      let findIndex = state.notifacations.findIndex(
        (notifacation) => notifacation.id === payload.id
      );
      state.notifacations[findIndex].is_read = true;
    },
  },
  actions: {
    async notifacationsList({ commit }) {
      commit("setLoading", true);
      instance
        .get(`farmer/notifications`)
        .then((data) => {
          commit("setLoading", false);
          commit("setNotifacations", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    async notifacationsIsRead({ commit }, payload) {
      instance
        .post(`farmer/notifications/${payload.id}`)
        .then(() => {
          commit("setNotifacationRead", payload);
        })
        .catch((error) => console.log(error));
    },

    async realTimeNotifacations({ commit }) {
      const messaging = getMessaging();
      onMessage(messaging, () => {
        commit("setIsNotifacations", true);
        const audio = new Audio("/alert.wav");
        audio.play();
      });
    },
  },
};
export default notifacationsModule;
