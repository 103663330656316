// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDac2EALQiqE0pZHGlFUj6OBjFK3miJR50",
  authDomain: "ncpd-lms.firebaseapp.com",
  databaseURL: "https://ncpd-lms-default-rtdb.firebaseio.com",
  projectId: "ncpd-lms",
  storageBucket: "ncpd-lms.appspot.com",
  messagingSenderId: "431254363535",
  appId: "1:431254363535:web:b06ced361fff6a442a7ffa",
  measurementId: "G-VG4BT2VRBD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const db = getFirestore();

export { db, app };
