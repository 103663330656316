import instance from "@/plugin/axios.js";

const settingsModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    settings: {},
  },
  getters: {
    getIsSettingLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getSettings: (state) => state.settings,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    setSettings: (state, payload) => {
      state.settings = payload;
    },
  },
  actions: {
    async allSettings({ commit }) {
      commit("setLoading", true);
      instance
        .get("settings")
        .then((data) => {
          commit("setSettings", data.data.data.site_settings);
          commit("setLoading", false);
        })
        .catch((error) => console.log(error));
    },
  },
};

export default settingsModule;
