import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { createMetaManager } from "vue-meta";
// Cookies
import VueCookies from "vue-cookies";
//
import VueSocialSharing from "vue-social-sharing";

//

//
import "@fortawesome/fontawesome-free/css/all.css";
// Bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";

// Customize Select Component
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//
import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

//

const myVideoSettings = {
  dashboard: {
    btn: {
      play: "تشغيل",
      pause: "ايقاف",
      fullscreen: "كامل الشاشة",
      exitFullscreen: "الخروج من وضع ملئ للشاشة",
      mute: "إيقاف الصوت",
      unmute: "تشغيل الصوت",
      back: "للخلف",
      pip: "فتح كنافذة",
    },
    settings: {
      autoplay: "تشغيل تلقائى",
      loop: "اعادة تلقائى",
      speed: "سرعة الفبدبو",
      resolution: "جودة",
    },
  },
  layers: {
    error: {
      title: "خطاء!",
      2404: "مصدر الفيديو غير محدد",
      2502: "خطأ في شبكة الوسائط",
      2503: "لا يمكن فك تشفير الفيديو",
      2504: "لا يمكن تشغيل الفيديو!",
      601: "لا يمكن تشغيل الفيديو المباشر!",
    },
    loading: {
      msg: "جارى التحميل ...",
    },
  },
};

import "@/scss/main.scss";
const app = createApp(App);
app.component("v-select", vSelect);
app.use(VueCookies);
app.use(VueSocialSharing);
app.use(Vue3VideoPlayer, {
  lang: myVideoSettings,
});
app.use(store);
app.use(router);
app.use(createMetaManager()); // add this line

app.mount("#app");
