import { createStore } from "vuex";
import settingsModule from "./modules/settingsModule";
import authModule from "./modules/authModule";
import coursesModule from "./modules/coursesModule";
import userCoursesModule from "./modules/userModules/userCoursesModule";
import commentsModule from "./modules/userModules/commentsModule";
import discussionsModule from "./modules/userModules/discussionsModule";
import certificationsModule from "./modules/userModules/certificationsModule";
import testsModule from "./modules/userModules/testsModule";
import expertModule from "./modules/userModules/expertModule";
import surveysModule from "./modules/userModules/surveysModule";
import notifacationsModule from "./modules/userModules/notifacationsModule";

export default createStore({
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    settingsModule,
    authModule,
    coursesModule,
    // Private
    userCoursesModule,
    commentsModule,
    discussionsModule,
    certificationsModule,
    testsModule,
    expertModule,
    surveysModule,
    notifacationsModule,
  },
});
