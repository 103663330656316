import instance from "@/plugin/axios";
import Swal from "sweetalert2";
import router from "@/router";

// Success Added
let successfulAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "success",
    title: message,
  });
};

// // Error Request
let errorAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "error",
    title: message,
  });
};
const surveysModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    survey: [],
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getSurvey: (state) => state.survey,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    setSurvey: (state, payload) => {
      state.survey = payload;
    },
  },
  actions: {
    async surveyByID({ commit }, payload) {
      commit("setLoading", true);

      instance
        .get(`farmer/surveys/${payload}`)
        .then((data) => {
          commit("setLoading", false);
          commit("setSurvey", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    async sendSurveyAnswered({ commit }, payload) {
      instance
        .post(`farmer/surveys/${payload.survey_id}/answers`, payload.answered)
        .then((data) => {
          successfulAdded(data.data.message);
          commit("setLoading", false);
          router.push({
            name: "HomeCourseView",
          });
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
  },
};

export default surveysModule;
