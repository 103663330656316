<template>
  <div>
    <!-- ================================================================================================ -->
    <!-- SignUp -->
    <div class="SignUp">
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalSignUp"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable box-All-Modal-SignUp"
        >
          <div class="modal-content">
            <div class="box-modal py-3 px-3">
              <div
                class="d-flex align-items-center justify-content-between pb-2"
              >
                <div class="">
                  <h2>أهلاً بك 👋</h2>
                </div>
                <button
                  id="closeSignUp"
                  type="button"
                  class="btn-Close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>

              <div class="box-modal-LogIn-account px-4">
                <div class="my-2">
                  <h3>تسجيل حساب جديد</h3>
                </div>
                <form
                  class="needs-validation"
                  id="signUpForm"
                  @submit.prevent="handelRegister"
                >
                  <div class="pt-4 pb-2">
                    <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn">
                            الاسم كامل</label
                          >
                          <input
                            type="text"
                            class="form-control input-LogIn"
                            placeholder="أدخل الاسم كامل"
                            v-model="registerForm.name"
                            required
                            minlength="6"
                          />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn"
                            >البريد الإلكتروني
                          </label>
                          <input
                            type="email"
                            class="form-control input-LogIn"
                            placeholder="أدخل البريد الإلكتروني"
                            v-model="registerForm.email"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn">
                            كلمة المرور</label
                          >
                          <input
                            type="password"
                            class="form-control input-LogIn"
                            placeholder="أدخل كلمة المرور"
                            v-model="registerForm.password"
                            required
                            minlength="8"
                          />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn"
                            >تاكيد كلمة المرور</label
                          >
                          <input
                            type="password"
                            class="form-control input-LogIn"
                            placeholder="تاكيد كلمة المرور"
                            v-model="registerForm.password_confirmation"
                            @input="
                              checkvalidation(
                                'password',
                                registerForm.password_confirmation,
                                $event
                              )
                            "
                            required
                            minlength="8"
                          />
                          <div class="invalid-feedback">
                            كلمة السر غير متطابقة
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn">
                            رقم الجوال
                          </label>
                          <input
                            type="tel"
                            class="form-control input-LogIn"
                            placeholder="أدخل رقم الجوال"
                            v-model="registerForm.phone"
                            @input="
                              checkvalidation(
                                'phone',
                                registerForm.phone,
                                $event
                              )
                            "
                            minlength="10"
                            maxlength="10"
                            required
                          />
                          <div class="invalid-feedback">
                            يجب ان يبدأ رقم الجوال 05
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn"
                            >رقم الهوية الوطنية</label
                          >
                          <input
                            type="number"
                            class="form-control input-LogIn"
                            placeholder="رقم الهوية الوطنية"
                            v-model="registerForm.national_id"
                            minlength="10"
                            maxlength="10"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn"
                            >رقم السجل الزراعي
                            <span> (اختيارى) </span>
                          </label>
                          <input
                            type="number"
                            class="form-control input-LogIn"
                            placeholder="رقم السجل الزراعي"
                            v-model="registerForm.agricultural_log"
                            minlength="10"
                            maxlength="10"
                          />
                        </div>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn"> الجنس </label>
                          <select
                            class="form-select select-SignUp"
                            aria-label="Default select example"
                            v-model="registerForm.gender"
                            required
                          >
                            <option selected disabled>أختار النوع</option>
                            <option value="رجل">ذكر</option>
                            <option value="إمرأة">انثي</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div
                          class="row justify-content-center align-items-center"
                        >
                          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            <div class="py-4">
                              <button
                                :disabled="this.isLoging || this.preventSubmit"
                                type="submit"
                                class="btn-sign-in"
                                aria-label="submit"
                              >
                                <span v-if="!this.isLoging"> إنشاء حساب </span>
                                <div
                                  v-if="this.isLoging"
                                  class="spinner-border text-light"
                                  role="status"
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </button>
                              <div class="text-center pt-4">
                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModalSignIn"
                                  class="Register-a-new-account"
                                  aria-label="submit"
                                >
                                  لدي حساب بالفعل ؟ تسجيل الدخول
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ================================================================================================ -->
    <!-- SignIn -->
    <div class="SignIn">
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModalSignIn"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="box-modal py-5 px-3">
              <div
                class="d-flex align-items-center justify-content-between pb-2"
              >
                <div class="">
                  <h2>مرحبا بعودتك 👋</h2>
                </div>
                <button
                  type="button"
                  class="btn-Close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeSignIn"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>

              <div class="box-modal-LogIn-account px-4">
                <div class="my-2">
                  <h3>تسجيل الدخول إلى حسابك</h3>
                </div>
                <form @submit.prevent="handelLogin">
                  <div class="pt-4 pb-2">
                    <div class="py-3">
                      <label class="form-label laboel-LogIn"
                        >البريد الإلكتروني</label
                      >
                      <input
                        type="email"
                        class="form-control input-LogIn"
                        placeholder="أدخل البريد الإلكتروني"
                        v-model="loginForm.email"
                        required
                      />
                    </div>
                    <div class="py-3">
                      <label class="form-label laboel-LogIn"
                        >كلمة المرور
                      </label>
                      <input
                        type="password"
                        class="form-control input-LogIn"
                        placeholder="أدخل كلمة المرور"
                        v-model="loginForm.password"
                        required
                        minlength="8"
                      />
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between py-1"
                    >
                      <div>
                        <input
                          class="checkbox-remember-me"
                          type="checkbox"
                          :value="true"
                          @change="remoberMyEmail"
                          v-model="remberMe"
                          :disabled="loginForm.email == ''"
                          id="remember-me"
                        />
                        <label for="remember-me" class="label-remember-me"
                          >تذكرنى</label
                        >
                      </div>
                      <div>
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalForgotYourPassword"
                          class="btn-Forgot-your-password"
                          aria-label="reset Password"
                        >
                          نسيت كلمة المرور؟
                        </button>
                      </div>
                    </div>

                    <div class="py-4">
                      <button
                        :disabled="this.isLoging"
                        type="submit"
                        class="btn-sign-in"
                        aria-label="submit"
                      >
                        <span v-if="!this.isLoging">تسجيل الدخول</span>
                        <div
                          v-if="this.isLoging"
                          class="spinner-border text-light"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </button>
                      <div class="text-center pt-4">
                        <button
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalSignUp"
                          class="Register-a-new-account"
                          aria-label="Register"
                        >
                          تسجيل حساب جديد
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ================================================================================================ -->
    <!-- Forgot-your-password -->
    <div>
      <div class="Forgot-your-password">
        <!-- Modal -->
        <div
          class="modal fade"
          id="exampleModalForgotYourPassword"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          >
            <div class="modal-content">
              <div class="box-modal py-5 px-3">
                <div
                  class="d-flex align-items-center justify-content-between pb-2"
                >
                  <div class="">
                    <h2>نسيت كلمة المرور</h2>
                  </div>
                  <button
                    type="button"
                    class="btn-Close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </button>
                </div>

                <div class="box-modal-LogIn-account px-4">
                  <div class="py-5">
                    <div class="py-5">
                      <form @submit.prevent="handelForget">
                        <div class="py-3">
                          <label class="form-label laboel-LogIn"
                            >البريد الإلكتروني</label
                          >
                          <input
                            type="email"
                            class="form-control input-LogIn"
                            placeholder="أدخل البريد الإلكتروني"
                            v-model="forgetForm.email"
                          />
                        </div>
                        <div class="py-4">
                          <button
                            :disabled="this.isLoging"
                            type="submit"
                            class="btn-sign-in"
                            aria-label="Reset"
                          >
                            <span v-if="!this.isLoging">
                              إعادة تعيين كلمة المرور
                            </span>
                            <div
                              v-if="this.isLoging"
                              class="spinner-border text-light"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ================================================================================================ -->
    <!-- Reset-Password -->
    <div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="resetPassword"
        tabindex="-1"
        aria-labelledby="resetPasswordLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title">إعادة تعيين كلمة السر</h3>
              <button
                type="button"
                class="btn-Close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeResetPassword"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="handelReset">
                <div class="form-group">
                  <label class="form-label laboel-LogIn">الكود</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="أدخل الكود"
                    v-model="resetFrom.code"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label laboel-LogIn"
                    >كلمة المرور الجديدة</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    placeholder="أدخل الكود"
                    v-model="resetFrom.password"
                  />
                </div>
                <div class="form-group">
                  <label class="form-label laboel-LogIn"
                    >تاكيد كلمة المرور الجديدة</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    placeholder="أدخل الكود"
                    v-model="resetFrom.password_confirmation"
                  />
                </div>
                <div class="form-group">
                  <button
                    :disabled="this.isLoging"
                    type="submit"
                    aria-label="submit"
                    class="btn-sign-in"
                  >
                    <span v-if="!this.isLoging"> تغيير كلمة السر </span>
                    <div
                      v-if="this.isLoging"
                      class="spinner-border text-light"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ================================================================================================ -->
    <button
      id="openSendOnEmailPassword"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#SendOnEmailPassword"
      aria-label="SendOnEmailPassword"
    ></button>
    <!-- Send-on-Email-password -->
    <div>
      <div class="Forgot-your-password">
        <!-- Modal -->
        <div
          class="modal fade"
          id="SendOnEmailPassword"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
          >
            <div class="modal-content">
              <div class="py-5 px-5">
                <div class="box-SendOnEmailPassword text-center">
                  <div class="py-5">
                    <div class="pb-5">
                      <img
                        width="165"
                        height="165"
                        class=""
                        rel="preload"
                        src="../assets/images/SendOnEmailPassword.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <h2>
                        تم إرسال على البريد الإلكتروني الخاص بك رابط إعادة تعيين
                        كلمة المرور
                      </h2>
                      <p>
                        من المفترض ان تتلقى قريبا رسالة على البريد الإلكتروني
                        يسمح لك بإعادة تعيين كلمة المرور الخاصة بك، يرجى التأكيد
                        من البريد الإلكتروني
                      </p>
                      <button
                        type="button"
                        id="openResetPassword"
                        class="btn-sign-in"
                        data-bs-toggle="modal"
                        data-bs-target="#resetPassword"
                        aria-label="resetPassword"
                      >
                        إعادة كلمة السر
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ================================================================================================ -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import instance from "@/plugin/axios.js";
import Swal from "sweetalert2";

export default {
  name: "SignInComponent",
  components: {},
  data: () => ({
    isAuth: false,
    isLoging: false,
    isForget: false,

    forgetForm: {
      email: "",
    },
    fcm_token: null,
    loginForm: {
      email: localStorage.getItem("email") || "",
      password: "",
    },
    resetFrom: {
      code: "",
      password: "",
      password_confirmation: "",
    },
    registerForm: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      avatar_image: "",
      phone: "",
      gender: "",
      agricultural_log: "",
      national_id: "",
    },
    remberMe: false,
    preventSubmit: false,
  }),
  methods: {
    ...mapActions("authModule", ["handleUserNotification"]),
    ...mapActions("authModule", ["userForget"]),
    ...mapMutations("authModule", ["isLogin", "setUserInfo"]),

    remoberMyEmail() {
      if (this.remberMe) {
        localStorage.setItem("email", this.loginForm.email);
      } else {
        localStorage.removeItem("email");
      }
    },

    checkvalidation(key, value, e) {
      let el = e.target.nextSibling;
      if (key == "phone") {
        if (!value.startsWith("05")) {
          this.preventSubmit = true;
          el.classList.add("was-validated");
        } else {
          this.preventSubmit = false;
          el.classList.remove("was-validated");
        }
      } else if (key == "password") {
        if (value !== this.registerForm.password) {
          this.preventSubmit = true;
          el.classList.add("was-validated");
        } else {
          this.preventSubmit = false;
          el.classList.remove("was-validated");
        }
      }
    },

    successfulAdded(message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: message,
      });
    },

    // // Error Request
    errorAdded(message) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: message,
      });
    },

    async handelLogin() {
      this.isLoging = true;
      this.loginfunc();
      if ((await Notification.requestPermission()) == "granted") {
        this.handleUserNotification();
      }
    },

    async loginfunc() {
      let button = document.getElementById("closeSignIn");

      instance
        .post("auth/login", this.loginForm)
        .then((data) => {
          button.click();
          localStorage.setItem("token", data.data.token.access_token);
          localStorage.setItem("isAuth", true);
          localStorage.setItem("userInfo", JSON.stringify(data.data.data));
          // this.$cookies.set("userInfo",, "7d");
          this.setUserInfo(data.data.data);

          this.isLogin(true);
          this.successfulAdded("لقد تم تسجيل الدخول بنجاح");
          this.isLoging = false;
          // this.$router.replace({ name: "EducationalBoard" });
        })
        .catch((error) => {
          this.errorAdded(error.response.data.message);
          this.isLoging = false;
        });
    },
    async handelRegister() {
      if (this.registerForm.agricultural_log == "") {
        delete this.registerForm.agricultural_log;
      }
      this.registerFunc();
      if ((await Notification.requestPermission()) == "granted") {
        this.handleUserNotification();
      }
    },

    async registerFunc() {
      let button = document.getElementById("closeSignUp");

      instance
        .post("auth/register", this.registerForm)
        .then((data) => {
          button.click();
          localStorage.setItem("token", data.data.token.access_token);
          localStorage.setItem("isAuth", true);
          localStorage.setItem("userInfo", JSON.stringify(data.data.data));
          this.successfulAdded("لقد تم انشاء الحساب بنجاح");
          this.setUserInfo(data.data.data);
          this.isLogin(true);
          this.successfulAdded("لقد تم تسجيل الدخول بنجاح");
          this.isLoging = false;
          this.clearRegisterForm();
          this.$router.replace({ name: "EducationalBoard" });
        })
        .catch((error) => {
          this.errorAdded(error.response.data.message);
          this.isLoging = false;
        });
    },

    clearRegisterForm() {
      this.registerForm = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        avatar_image: "",
        phone: "",
        gender: "",
        agricultural_log: "",
        national_id: "",
      };
    },

    async handelForget() {
      let button = document.getElementById("openSendOnEmailPassword");
      this.isLoging = true;

      instance
        .post("auth/password/email", this.forgetForm)
        .then(() => {
          this.successfulAdded(
            "لقد تم ارسال ارسال الرسالة الى البريد الالكترونى"
          );
          button.click();
          this.isLoging = false;
        })
        .catch((error) => {
          this.errorAdded(error.response.data.message);
          this.isLoging = false;
        });
    },

    handelReset() {
      this.isLoging = true;
      let button = document.getElementById("closeResetPassword");

      instance
        .post("auth/password/code/check", this.resetFrom)
        .then(() => {
          this.successfulAdded("لقد تم تغيير كلمة السر");
          button.click();
          this.isLoging = false;
        })
        .catch((error) => {
          this.errorAdded(error.response.data.message);
          this.isLoging = false;
        });
    },
  },
  created() {},
  computed: {
    ...mapGetters("authModule", ["getIsAuth", "getIsForget", "getFCM"]),
  },
  mounted() {
    this.isForget = this.getIsForget;
    this.fcm_token = this.getFCM;
    this.isAuth = this.getIsAuth;
  },
  watch: {
    getIsAuth() {
      this.isAuth = this.getIsAuth;
    },
    getIsForget() {
      this.isForget = this.getIsForget;
    },
    getFCM() {
      this.fcm_token = this.getFCM;
    },
  },
};
</script>
<style lang="scss" scoped>
$main-color: #a7a260;

// ----------------------------------------------------------------------------------------------------
// SignIn & SignUp
.modal-dialog-scrollable .modal-content {
  border: solid #dddddd 1px !important;
  border-radius: 20px !important;
  overflow-y: auto;
}

.btn-Close {
  width: 32px;
  height: 32px;
  border: 2px solid #d1d1d1;
  color: #d1d1d1;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-modal h2 {
  font-size: 28px;
  color: #798893;
  font-weight: 100;
}

.box-modal-LogIn-account h3 {
  font-size: 26px;
  color: #434343;
  font-weight: 100;
}

.laboel-LogIn {
  color: #434343;
  font-size: 20px;

  span {
    font-size: 16px;
    color: #ccc;
  }
}
.box-modal-LogIn-account .select-SignUp,
.box-modal-LogIn-account .input-LogIn {
  border: #efefef solid 1px;
  box-shadow: none;
  width: 100% !important;
  height: 58px;
  border-radius: 6px !important;
  // color: #dedede;
  font-size: 18px;
}
.box-modal-LogIn-account .select-SignUp {
  color: #dedede;
}
.box-modal-LogIn-account .select-SignUp:focus,
.box-modal-LogIn-account .input-LogIn:focus {
  border: #efefef solid 1px;
  box-shadow: none;
  border-radius: 6px !important;
  // color: #dedede;
  font-size: 18px;
}

.box-modal-LogIn-account .input-LogIn::placeholder {
  color: #dedede;
  font-size: 18px;
}

.btn-Forgot-your-password {
  background-color: transparent;
  border: none;
  color: #798893;
  font-size: 16px;
}

.label-remember-me {
  color: #798893;
  font-size: 16px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.btn-sign-in {
  background-color: $main-color;
  height: 60px;
  width: 100%;
  border: none;
  color: #ffffff;
  font-size: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Register-a-new-account {
  background-color: transparent;
  border: none;
  color: $main-color;
  font-size: 22px;
  text-decoration: underline;
}

// SignUp
.box-All-Modal-SignUp {
  max-width: 970px !important;
}
// ----------------------------------------------------------------------------------------------------
// SendOnEmailPassword
.box-SendOnEmailPassword h2 {
  font-size: 22px;
  color: #434343;
  font-weight: 100;
  line-height: 50px;
}
.box-SendOnEmailPassword p {
  color: #798893;
  font-size: 18px;
  line-height: 50px;
}
// ----------------------------------------------------------------------------------------------------
</style>
