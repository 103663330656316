import instance from "@/plugin/axios.js";
import Swal from "sweetalert2";
import { getMessaging, getToken } from "firebase/messaging";
import { app } from "@/firebase/init.js";

// Success Added
let successfulAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "success",
    title: message,
  });
};

// // Error Request
let errorAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "error",
    title: message,
  });
};

let isAuth = localStorage.getItem("isAuth");
let parseIsAuth = JSON.parse(isAuth);

const messaging = getMessaging(app);

const authModule = {
  namespaced: true,
  state: {
    isAuth: parseIsAuth || false,
    userInfo: {},
    isForget: false,
    fcm: null,

    vapidKey:
      "BPEa5wcUOp434Zue2p0-z5hb5y0FqGCSwPEODb-cOapAEGqnw8YKk1pO6yxR0odT6RG5XUef5cjRahAtxQGIQHQ",
  },
  getters: {
    getIsAuth: (state) => state.isAuth,
    getUserInfo: (state) => state.userInfo,
    getFCM: (state) => state.fcm,

    getIsForget: (state) => state.isForget,
  },
  mutations: {
    isLogin: (state, payload) => {
      state.isAuth = payload;
    },
    setUserInfo: (state, payload) => {
      state.userInfo = payload;
    },
    setIsForget: (state, payload) => {
      state.isForget = payload;
    },
    setFCM: (state, payload) => {
      state.fcm = payload;
    },
  },
  actions: {
    async userForget({ commit }, payload) {
      instance
        .post("auth/password/email", payload)
        .then(() => {
          successfulAdded("لقد تم ارسال الرسالة الى البريد الالكترونى");
          commit("setIsForget", true);
        })
        .catch((error) => {
          commit("setIsForget", true);
          errorAdded(error.response.data.message);
        });
    },
    async profileData({ commit }) {
      instance
        .get("farmer/profile")
        .then((data) => {
          commit("setUserInfo", data.data.data);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async updateUserData({ commit }, payload) {
      await instance
        .post("farmer/profile/update-account", payload)
        .then((data) => {
          successfulAdded("لقد تم تعديل البيانات بنجاح");
          commit("setUserInfo", data.data.data);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async updateUserPassword({ commit }, payload) {
      await instance
        .post("farmer/profile/update-password", payload)
        .then(() => {
          successfulAdded("لقد تم تعديل كلمة السر بنجاح");
          commit("setUserInfo", payload);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },
    async updateUserImage({ commit }, payload) {
      await instance
        .post("farmer/profile/update-account", payload, {
          isFile: true,
        })
        .then((data) => {
          successfulAdded("لقد تم تعديل الصورة بنجاح");
          commit("setUserInfo", data.data.data);
        })
        .catch((error) => errorAdded(error.response.data.message));
    },

    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    async updateFCMToken({ state }) {
      await instance
        .post("farmer/profile/update-fcm", {
          fcm_token: state.fcm,
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error));
    },

    async handleUserNotification({ commit, state, dispatch }) {
      getToken(messaging, { vapidKey: state.vapidKey })
        .then((currentToken) => {
          commit("setFCM", currentToken);
          dispatch("updateFCMToken");
        })
        .catch((err) => {
          console.log(err);
          commit("setFCM", null);
        });
    },
  },
};

export default authModule;
