import instance from "@/plugin/axios";
// import Swal from "sweetalert2";

// // Success Added
// let successfulAdded = (message) => {
//   const Toast = Swal.mixin({
//     toast: true,
//     position: "top-end",
//     showConfirmButton: false,
//     timer: 3000,
//     timerProgressBar: true,
//     didOpen: (toast) => {
//       toast.addEventListener("mouseenter", Swal.stopTimer);
//       toast.addEventListener("mouseleave", Swal.resumeTimer);
//     },
//   });

//   Toast.fire({
//     icon: "success",
//     title: message,
//   });
// };

// // // Error Request
// let errorAdded = (message) => {
//   const Toast = Swal.mixin({
//     toast: true,
//     position: "top-end",
//     showConfirmButton: false,
//     timer: 3000,
//     timerProgressBar: true,
//     didOpen: (toast) => {
//       toast.addEventListener("mouseenter", Swal.stopTimer);
//       toast.addEventListener("mouseleave", Swal.resumeTimer);
//     },
//   });

//   Toast.fire({
//     icon: "error",
//     title: message,
//   });
// };
const commentsModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    comments: [],
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getComments: (state) => state.comments,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
  },
  actions: {
    async updateDiscussionComment({ commit }, payload) {
      instance
        .post(`farmer/discussions/45/comments/update/24`, payload, {
          isFile: true,
        })
        .then(() => {
          commit("setLoading", false);
        })
        .catch((error) => console.log(error));
    },
  },
};

export default commentsModule;
