import instance from "@/plugin/axios";
import Swal from "sweetalert2";
// import router from "@/router";

// Success Added
let successfulAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "success",
    title: message,
  });
};

// // Error Request
let errorAdded = (message) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: "error",
    title: message,
  });
};

const testsModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isFirstLoad: true,
    test: {},
    question: {},
    questionAnswers: {},
  },
  getters: {
    getIsLoading: (state) => state.isLoading,
    getIsFirstLoad: (state) => state.isFirstLoad,
    getTest: (state) => state.test,
    getQuestion: (state) => state.question,
    getQuestionAnswers: (state) => state.questionAnswers,
  },
  mutations: {
    // Loading
    setLoading: (state, payload) => {
      state.isLoading = payload;
    },
    // First load
    setFirstLoad: (state, payload) => {
      state.isFirstLoad = payload;
    },
    setTest: (state, payload) => {
      state.test = payload;
    },
    setQuestion: (state, payload) => {
      state.question = payload;
    },
    setQuestionAnswers: (state, payload) => {
      state.questionAnswers = payload;
    },
  },
  actions: {
    async testByID({ commit }, payload) {
      instance
        .get(`farmer/farmer-tests/${payload}`)
        .then((data) => {
          commit("setTest", data.data.data);
        })
        .catch((error) => console.log(error));
    },
    async testQuestionsByID({ commit }, payload) {
      commit("setLoading", true);
      instance
        .get(`farmer/farmer-tests/${payload}/get-next-question`)
        .then((data) => {
          commit("setQuestion", data.data.data);
          commit("setLoading", false);
        })
        .catch((error) => {
          errorAdded(error.response.data.message);
        });
    },
    async answeredQuestion({ commit, dispatch }, payload) {
      instance
        .post(
          `farmer/farmer-tests/${payload.course_id}/answer-question`,
          payload.answer
        )
        .then((data) => {
          successfulAdded(data.data.message);
          dispatch("testQuestionsByID", payload.course_id);
          commit("setLoading", false);
        })
        .catch((error) => console.log(error.response.data.message));
    },
    async testQuestionsAnswers({ commit }, payload) {
      commit("setLoading", true);
      instance
        .get(`farmer/farmer-tests/${payload}/answers`)
        .then((data) => {
          commit("setQuestionAnswers", data.data.data);
          commit("setLoading", false);
        })
        .catch((error) => {
          errorAdded(error.response.data.message);
        });
    },
  },
};

export default testsModule;
